<template>
  <v-row
    >
    <template
      v-if="!photo_error">
      <v-col cols="12">
        <div style="position:relative">
          <video 
            v-if="selected_photo === null"
            ref="camera"
            width="100%"
            height="auto"
            muted
            playsinline
            ></video>
          <v-img
            v-else
            :src="to_data_url(photos[order_index][selected_photo])"
            />
            <canvas ref="canvas" style="display:none"> </canvas>
          <div style="position: absolute; bottom: 15; width: 100%">
            <v-btn
              v-if="selected_photo === null && camera_started"
              rounded
              color="red"
              x-large
              @click="take_photo"
              >
              <v-icon color="white" x-large>mdi-record-circle-outline</v-icon>
            </v-btn>
            <v-btn
              v-else-if="selected_photo"  
              icon
              outlined
              color="red lighten-2"
              x-large
              @click="delete_photo"
              class="mb-2"
              >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </div>
          <div
            style="position: absolute; top: 10; right:10"
            >
            <v-btn
              fab
              color="grey"
              small
              @click="close_image"
              v-if="selected_photo !== null"
              >
              <v-icon color="white">mdi-window-close</v-icon>
            </v-btn>
            <v-btn 
              v-else-if="show_camswitch"
              fab
              color="grey"
              small
              @click="switch_camera"
              >
              <v-icon color="white">mdi-camera-flip-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex flex-row flex-wrap">
        <div 
          v-for="(photo, index) in (photos[order_index] || [])"
          :key="index"
          class="ma-1"
          style="width: 20%; display: block; position: relative">
          <v-img
            :src="to_data_url(photo)"
            contain
            style="cursor: pointer"
            @click="select_photo(index)"
            ></v-img>
            <v-btn
                fab
                absolute
                color="grey"
                height="14px"
                width="14px"
                style="top:0px; right: 0px"
                @click.stop="delete_photo(index)"
                >
                <v-icon color="white" small>mdi-window-close</v-icon>
              </v-btn>
        </div>
            <!-- </div> -->
        
        <!-- <v-btn @click="take_photo">Snap</v-btn> -->
      </v-col>
      </template>
      <template v-else>
        <v-alert
        type="error"
        class="mx-4"
        dense
        >
        Your company require parcel photos but your camera is unavailable. Make sure to allow access to your camera or turn off parcel photos.
      </v-alert>
      </template>
  </v-row>
  
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    order_index: {
      type: Number, 
      required: true,
    }
  },
  data(){
    return {
      picture: null,
      selected_photo: null,
      show_camswitch: true,
      camera_started: false,
      video: null,
      camera_faces_user: false,
      photo_error: false,
    }
  },
  computed: {
    ...mapState({
      photos: state => state.order.photos
    })
  },
  methods: {
    delete_photo(photo_index){
      this.$store.commit('order/REMOVE_PHOTO', {order_index: this.order_index, photo_index: this.selected_photo || photo_index || 0 })
      if(this.selected_photo !== null) this.close_image()
    },
    select_photo(index){
      this.selected_photo = index
    },
    to_data_url(blob){
      return blob && URL.createObjectURL(blob)
    },
    take_photo(){
      console.log(this.$refs.camera.clientHeight)
      const canvas = this.$refs.canvas
      const video = this.$refs.camera
      const context = canvas.getContext('2d');
      canvas.width = this.$refs.camera.clientWidth;
      canvas.height = this.$refs.camera.clientHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      // const photo = canvas.toDataURL('image/png');
      canvas.toBlob(photo => this.$store.commit('order/SET_PHOTOS', {order_index: this.order_index, photo }), 'image/png')
      // this.$store.commit('order/SET_PHOTOS', {order_index: this.order_index, photo })
    },
    close_image(){
      this.selected_photo = null
      this.start_camera()
    },
    start_camera(){
      this.stop_camera()
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: this.camera_faces_user ? 'user' : 'environment'
        }
      })
      .then(stream => {
        this.video = this.$refs.camera
        this.video.srcObject = stream
        this.video.onloadedmetadata = () => {
          this.video.play();
          this.camera_started = true
        };
      })
      .catch(() => this.photo_error = true)
    },
    stop_camera(){
      return this.video && this.video.srcObject && this.video.srcObject.getTracks().map(t => t.stop());
    },
    switch_camera(){
      this.camera_faces_user = !this.camera_faces_user
      this.start_camera()
    },
    async log_cameras(){
      if(this.$store.state.app.logged_camera) return
      const cameras = []
      if (navigator.mediaDevices?.enumerateDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices()
              for(const device of devices){
                if(device.kind !== 'videoinput'){ cameras.push({ kind: device.kind }); continue }
                const camera = {device, capabilities: [], settings: []}
                const stream = await navigator.mediaDevices.getUserMedia( { video: { deviceId: { exact: device.deviceId } } } );
                stream.getVideoTracks().forEach( track => {
                        const capabilities = track.getCapabilities();
                        camera.capabilities.push(capabilities)
                        const settings = track.getSettings();
                        camera.settings.push(settings)
                    }
                )
                stream.getTracks().forEach( track => track.stop() );
                cameras.push(camera)
              }
      this.$store.commit('app/SET_STATE', {logged_camera: true})
      this.$store.dispatch('app/submit_log', { message: `Camera log\n\nUser: ${this.$store.state.user.userProfile.name}\n\n${JSON.stringify(cameras, null, 3)}`})
    }
    }
  },
  beforeDestroy(){
    this.stop_camera()
  },
  async mounted(){
    await this.log_cameras()
    this.start_camera({ facingMode: 'environment' })
  }
    
}
</script>
<style lang="scss" scoped>
  video {
    position: relative;
  }
</style>