<template>
 <v-container
    fluid
    tag="section"
  >
    
      <!-- {{ get_pick_order.map(item => item.name).toString() }} -->
  <v-card class="text-center">
   <v-system-bar
    color="warning"
    class="py-4 mb-4"
    >
    Active bucket
  </v-system-bar>
    <v-row 
      class="text-center"
      dense
      >
      <v-col cols="3">
        <p class="text-caption mb-0">User</p>
        {{ bucket.user_name }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Started at</p>
        {{ bucket.started_at | DateFilter }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Last updated</p>
        {{ bucket.last_updated_at | DateFilter }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Parcels</p>
        {{ bucket.orders.length }}
      </v-col>
    </v-row>
   
      <v-container class="pa-6">
        <v-row>
          <!-- <v-col cols="6">
            <v-btn x-large color="secondary" dark rounded outlined>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="12">
            <v-btn :loading="loading" x-large color="green" class="mt-12" dark rounded @click="recover_bucket({hard_refresh: false})">
              Claim & continue
            </v-btn>
          </v-col>
          <v-col cols="12">  
            <v-btn :loading="loading" large color="primary"  dark rounded @click="recover_bucket({hard_refresh: true})">
              Claim & Restart
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
    
 </v-container>

</template>

<script>


// import { mapGetters } from 'vuex'
import { DateFilter } from '@/utils/filters'

export default {
  components: {
    
  },
  props: {
    bucket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // ...mapGetters(
    //   'order', ['get_order_bucket_position']
    // )
  },
  filters: {
    DateFilter,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async recover_bucket( {hard_refresh } ){
      this.loading = true
      await this.$store.dispatch('order/recover_bucket', { from_id: this.bucket.user_id, })
      await this.$store.dispatch('order/get_orders', { hard_refresh })
      this.loading = false
    }
  },
  mounted(){

  },

  
}
</script>
<style lang="scss" scoped>
.edit-btn {
  position: absolute;
  right: 5px; 
  bottom: 5px;
}
.bucket-col {
  flex-wrap: nowrap;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.87)
}
.green-container {
  background-color: #a6f7c6
}

.remove-button {
  align-self: center;
  right:0;
}

.item-picked {
  background-color:rgba(76, 175, 80, 0.2) !important;
  
  border: 1px solid green
}
</style>