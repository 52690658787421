<template>
    <v-card
      round
      class="my-4 pb-2"
      outlined
      >
      <v-dialog
      v-model="package_modal"
      width="700"
      :fullscreen="$vuetify.breakpoint.xsOnly"
        >
            <v-card>
              <v-form ref="new_package_form" @submit.prevent="save_package">
                <v-row  class="grey lighten-2" dense>
                  <v-col class="grow">
                    <v-card-title class="text-h5 ">
                      Add package
                    </v-card-title>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      icon
                      @click="package_modal = false"
                      ><v-icon>mdi-close</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-card-text class="mt-8">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field 
                        round
                        outlined
                        v-model="new_package.name"
                        :rules="[v => !!v || 'Required']"
                        label="Package name"
                        />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="package_types"
                        item-name="text"
                        item-value="value"
                        v-model="new_package.type"
                        label="Package type"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" class="d-flex" style="gap:10px">
                      <v-text-field
                        type="number"
                        outlined
                        v-model="new_package.dimensions.length"
                        :rules="[v => v > 0 || 'Required']"
                        round
                        label="Length"/>
                        <v-text-field
                        type="number"
                        outlined
                        v-model="new_package.dimensions.width"
                        :rules="[v => v > 0 || 'Required']"
                        round
                        label="Width"/>
                        <v-text-field
                        type="number"
                        outlined
                        v-model="new_package.dimensions.height"
                        :rules="[v => v > 0 || 'Required']"
                        round
                        label="Height"/>
                        <v-select
                          :items="dimension_units"
                          item-text="text"
                          item-value="value"
                          v-model="new_package.dimensions.unit"
                          ></v-select>
                    </v-col>
                    
                    <v-col cols="12" class="d-flex" style="gap: 10px">
                      <v-text-field
                        type="number"
                        outlined
                        v-model="new_package.weight.value"
                        :rules="[v => v > 0 || 'Required']"
                        round
                        label="Weight (when empty)"/>
                        <v-select
                          :items="weight_units"
                          item-text="text"
                          item-value="value"
                          v-model="new_package.weight.unit"
                          ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="pa-6">
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="saving_package"
                    >Save</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
      </v-dialog>
      <v-form @submit.prevent="save_method" ref="methods_form">
        <v-card-text v-if="!order.sf_data.shipping_service">
          <!-- <v-row>
            <v-col cols="12" md="6">
            </v-col>
          </v-row> -->
          <!-- <p>Connect {{ order.shipping_lines[0] }}</p>
          <v-select
            label="Carrier"
            /> -->
            BLOCK 1
        </v-card-text>
        <v-card-text v-else-if="order.sf_data.shipping_service.type === 'shopify'">
          <v-card
          round
          class="my-4 pb-2"
          color="rgba(76, 175, 80, .2)"
          v-if="!has_mapped_shipping_service"
          >
          <v-card-title class="text-h6" style="word-break: break-word !important">
            This customer purchased shipping method {{ order.shipping_lines[0].code }}
          </v-card-title>
          <v-card-subtitle class="text-left">
            No default carrier & service is setup with this shipping method.
          </v-card-subtitle>
          <v-card-text>
            <v-select
              v-if="!carriers.some(i => i.value === order.shipping_lines[0].source)"
              v-model="source"
              :items="carriers"
              item-text="name"
              item-value="value"
              outlined
              :rules="[v => !!v || 'Required']"
              label="Carrier"/>
            <v-select
              v-if="
                (carriers.some(i => i.value === order.shipping_lines[0].source || i.value === source)
                && !carriers.find(i => i.value === order.shipping_lines[0].source || i.value === source).services.some(i => i.value === order.shipping_lines[0].code))
                "
              v-model="service"
              label="Service"
              :items="carriers.find(i => i.value === source || i.value === order.shipping_lines[0].source).services"
              item-text="name"
              item-value="value"
              :rules="[v => !!v || 'Required']"
              outlined
              />
            </v-card-text>
          </v-card>
            <v-select
              label="Package"
              :items="company.packages"
              no-data-text="No packages"
              v-model="selected_package"
              :rules="[v => !!v || !loaded_packages || 'Required']"
              outlined
              return-object
              >
            <template v-slot:selection="{ item }">
              <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ package_types.find(i => i.value === item.type).text }} {{ item.dimensions.width }} x {{ item.dimensions.length }} x {{ item.dimensions.height }} {{ dimension_units.find(i => i.value === item.dimensions.unit).text }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ item, on, attrs}">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ package_types.find(i => i.value === item.type).text }} {{ item.dimensions.width }} x {{ item.dimensions.length }} x {{ item.dimensions.height }} {{ dimension_units.find(i => i.value === item.dimensions.unit).text }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item @click="(package_modal = true)">
                <v-list-item-action color="grey lighten-3">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Add package
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

          <v-btn
            color="secondary"
            :disabled="!selected_package"
            type="submit">Buy label</v-btn>
        </v-card-text>
      </v-form>
    </v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { uid } from 'uid'


export default {
  components: {
    
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: null
    },
    store: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapState({
      company: s => s.warehouse.company,
      carriers: s => s.app.config.shopify_labels.carriers
    }),
    ...mapGetters({
      
    }),
    has_mapped_shipping_service(){
      return (this.order.shipping_lines 
        && this.order.shipping_lines[0]
        && this.carriers.some(c => 
          c.value === this.order.shipping_lines[0].source
          && c.services.some(s => s.value === this.order.shipping_lines[0].code)
        ))
        || 
        (this.order.sf_data.shipping_service 
        && this.order.sf_data.shipping_service.source
        && this.order.sf_data.shipping_service.service)
    },
  },
  watch: {
    
  },
  data() {
    return {
      source: '',
      service: '',
      loaded_packages: false,
      selected_package: null,
      package_modal: false,
      saving_package: false,
      dimension_units: [
        {value: 'CENTIMETERS', text: 'cm'},
        {value: 'INCHES', text: 'in'}
      ],
      weight_units: [
        {value: 'GRAMS', text: 'g'},
        {value: 'KILOGRAMS', text: 'kg'},
        {value: 'OUNCES', text: 'oz'},
        {value: 'POUNDS', text: 'lb'},
      ],
      package_types: [
        {value: 'BOX', text: 'Box'},
        {value: 'ENVELOPE', text: 'Envelope'},
        {value: 'SOFT_PACK', text: 'Soft package or satchel'},
        {value: 'FLAT_RATE', text: 'Flat rate package (USPS / FedEx)'},
      ],
      new_package: {
        name: '',
        type: 'BOX',
        id: '',
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
          unit: 'CENTIMETERS'
        },
        weight: {
          value: 0,
          unit: 'GRAMS'
        }
      }
    }
  },
  methods: {
    async save_method(){
      if(!this.selected_package) return
      if(!this.has_mapped_shipping_service){
        await this.$store.dispatch('warehouse/save_data_source', {
          id: this.store.id,
          shopify_shipping_map: (this.store.shopify_shipping_map || []).concat([{
            order_shipping_code: this.order.shipping_lines[0].code,
            corresponding_code: {
              source: this.source,
              service: this.service,
            }
          }])
        })
        this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id, exclude: this.order.uuid })
        this.$emit('save', {selected_package: this.selected_package, source: this.source, service: this.service })
      }
      else this.$emit('save', {selected_package: this.selected_package, source: this.source, service: this.service })
    },
    async save_package(){
      if(!this.$refs.new_package_form.validate()) return
      this.new_package.id = uid()
      this.new_package.dimensions.length = Number(this.new_package.dimensions.length)
      this.new_package.dimensions.height = Number(this.new_package.dimensions.height)
      this.new_package.dimensions.width = Number(this.new_package.dimensions.width)
      this.new_package.weight.value = Number(this.new_package.weight.value)
      this.saving_package = true
      this.$store.commit('warehouse/SET_COMPANY', { packages: this.company.packages.concat([ this.new_package ]) })
      await this.$store.dispatch('warehouse/save_company', ['packages'])
      this.selected_package = this.new_package
      // this.$emit('save', this.new_package)
      this.saving_package = false
      this.package_modal = false
    }
  },
  mounted(){
    this.selected_package = this.company.packages && this.company.packages[0] || null
    this.$nextTick( () => this.loaded_package = true)
  }
}
</script>