<template>
 <v-container
    fluid
    tag="section"
    class="px-0 px-md-3"
  >
    
      <!-- {{ get_pick_order.map(item => item.name).toString() }} -->
  <v-card 
    class="text-center pt-3 pt-md-6 mx-sm-auto" 
    :disabled="!active"
    max-width="600px"
    >
   <v-system-bar
    color="warning"
    class="py-4 mb-4"
    >
    Return to shelf
  </v-system-bar>
    <p class="text-subtitle-2">
      {{ item.fulfilled_quantity }}x {{ item.name }}
    </p>
    
    <v-row 
      class="text-center text-h2"
      dense
      >
      <v-col cols="3">
        <p class="text-caption mb-0">Shelve</p>
        {{ item.sf_position && item.sf_position.shelve }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Shelf</p>
        {{ (item.sf_position && item.sf_position.shelf) | LetterFilter }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Slot</p>
        {{ (item.sf_position && item.sf_position.slot) | OneIndexFilter }}
      </v-col>
      <v-col cols="3">
        <p class="text-caption mb-0">Product</p>
        {{ (item.sf_position && item.sf_position.product) | LetterFilter }}
      </v-col>
    </v-row>
   
      <v-container class="pa-6">
        <v-row>
          <!-- <v-col cols="6">
            <v-btn x-large color="secondary" dark rounded outlined>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="12">
            <v-btn x-large color="green" dark rounded @click="$emit('done')">
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
    
 </v-container>

</template>

<script>

import { OneIndexFilter, LetterFilter } from '@/utils/filters'
// import { mapGetters } from 'vuex'

export default {
  components: {
    
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    // ...mapGetters(
    //   'order', ['get_order_bucket_position']
    // )
  },
  filters: {
    OneIndexFilter,
    LetterFilter,
  },
  data() {
    return {
      color: '',
    }
  },
  methods: {
    
  },
  mounted(){

  },

  
}
</script>
<style lang="scss" scoped>
.edit-btn {
  position: absolute;
  right: 5px; 
  bottom: 5px;
}
.bucket-col {
  flex-wrap: nowrap;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.87)
}
.green-container {
  background-color: #a6f7c6
}

.remove-button {
  align-self: center;
  right:0;
}

.item-picked {
  background-color:rgba(76, 175, 80, 0.2) !important;
  
  border: 1px solid green
}
</style>