<template>
    <div>
     <v-alert
      :type="error.type || 'error'"
      class="mx-4"
      dense
      v-if="!fields || !fields.length"
      >
      {{ error.message }}
    </v-alert>
    <v-card
      outlined
      class="mx-4"
      v-else>
      <v-form @submit.prevent="submit" ref="order_form">
        <!-- <v-card-title>
          Update address
        </v-card-title> -->
        <v-card-text>
          <v-alert
          :type="error.type || 'error'"
          class=""
          dense
          >
          {{ error.message }}
        </v-alert>
          <template 
            v-for="(err, i) in fields"
            >
            <v-text-field
              v-if="err.type !== 'select'"
              :key="`input-${i}`"
              :label="err.label"
              v-model="fields[i].value"
              :rules="err.rules || []"
              outlined
              />
            <v-select   
              v-else
              :key="`select-${i}`"
              v-model="fields[i].value"
              :items="err.items"
              :label="err.label"
              :item-text="err['item-text']"
              :return-object="err['return-object']"
              >
            </v-select>
          </template>
            <v-btn type="submit" color="primary">Retry</v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>

// import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: null
    },
    error: {
      type: Object,
      required: true,
      default: null
    },
    index: {
      type: Number,
      required: true, 
      default: 0,
    }
  },
  computed: {

  },
  watch: {
   
  },
  data() {
    return {
      inputs: {
        'shipping_address.country_code': {
          label: 'Country Code',
          rules: [
            v => v.length === 2 || '2 letters',
          ]
        },
        'shipping_address.zip': {
          label: 'Zip code',
          rules: [
            v => !!v || 'Required',
          ]
        },
        'shipping_address.city': {
          label: 'City',
          rules: [
            v => !!v || 'Required',
            v => v.length < 36 || 'Max 35 characters'
          ]
        },
        'shipping_address.name': {
          label: 'Name',
          rules: [
            v => !!v || 'Required'
          ]
        },
        'sf_data.shipping_service.addons': {
          label: 'Addons',
          items: [
                { id: 'FE', description: 'Faktura, utan inlämningsunderlag'},
                { id: 'FR', description: 'Faktura, med inlämningsunderlag'}
                ],
          type: 'select',
          'item-text': 'description',
          'return-object': true
        }
      },
      fields: []
      // FE = faktura utan inlämningsunderlag
    }
  },
  methods: {
    submit() {
      if(!this.$refs.order_form.validate()) return
      // this.fields.map(({ key, value }) => ({ key, value }))
      for(const { key, value } of this.fields) this.$store.commit('order/SET_ORDER', { index: this.index, key, value })
      this.$emit('retry')
    },
    get(obj, target) {
      const path = target.split('.')
      return path.reduce((result, property) => {
        return result[property]
      }, obj)
    },
  },
  mounted(){
    this.fields = this.order && this.error.error_fields?.map(err => ({
      value: this.get(this.order, err),
      key: err,
      label: err, 
      ...this.inputs[err]
    })) || []
  
  }
}
</script>