<template>
 <v-container
    fluid
    tag="section"
    class="px-0 px-md-3"
  >
      <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
        >
            <v-card>
                <v-row  class="grey lighten-2">
                  <v-col class="grow">
                    <v-card-title class="text-h5 ">
                      Remove item 
                    </v-card-title>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      icon
                      @click="dialog = false"
                      ><v-icon>mdi-close</v-icon></v-btn>
                  </v-col>
                </v-row>

                <v-card-text class="mt-8">
                  <v-textarea
                    outlined
                    rows="2"
                    name="reason"
                    no-resize
                    label="For what reason is this item being removed?"
                    :rules="[v => v && v.length > 5 || 'Required']"
                    v-model="reason"
                    />
                    <!-- TODO: AMOUNT ITEMS -->
                    <template  v-if="selected_order_for_removal && get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment">
                      <p>Of the {{ selected_order_for_removal.quantity - selected_order_for_removal.removed_quantity }} ordered items, how many are you removing?</p>
                      <div style="display:flex; justify-content: space-evenly">
                        <v-btn
                          color="red"
                          fab
                          dark
                          class="my-auto"
                          @click="removed_quantity--"
                          >
                          <v-icon>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-text-field
                          v-model="removed_quantity"
                          :rules="[
                            v => v > 0 || 'Must be greater than 0',
                            v => v <= selected_order_for_removal.quantity - selected_order_for_removal.removed_quantity || 'Must be fewer than ordered quantity'
                          ]"
                          hide-details
                          hide-spin-buttons
                          outlined
                          class="inventory-input text-h3"
                          ref="removed_quantity"
                          type="number"
                        />
                        <v-btn
                          color="green"
                          fab
                          dark
                          class="my-auto"
                          @click="removed_quantity++"
                          >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <v-alert
                      type="info"
                      v-if="selected_order_for_removal && selected_order_for_removal.order_uuid && get_order_by_uuid(selected_order_for_removal.order_uuid).line_items.length > 1"
                      class="mt-6"
                      >
                      <template v-if="get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment">
                        Other items in this order will remain and be partially fulfilled. To change this default behaviour, go to Settings > Stores and flip "Allow partial fulfillment" off.
                      </template>
                      <template v-else>
                        All items on this order will be dropped. If you wish to change this default behaviour, go to Settings > Stores and flip the switch "Allow partial fulfillment" on.
                      </template>
                    </v-alert>
                </v-card-text>

                

                <v-card-actions class="flex-column" v-if="selected_order_for_removal && selected_order_for_removal.order_uuid">
                    <v-btn
                      color="primary"
                      @click="removeItem({ override_default: false })"
                      block
                      :disabled="!!($refs.removed_quantity && $refs.removed_quantity.errorBucket.length)"
                    >
                      {{ get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment ? 'Remove item' : 'Remove item and drop order' }}
                    </v-btn>
                    <v-btn
                      text
                      class="my-2"
                      small
                      @click="removeItem({ override_default: true })"
                      :disabled="!!($refs.removed_quantity && $refs.removed_quantity.errorBucket.length)"
                    >
                      {{ get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment ? 'Remove item and drop order' : 'Remove item and keep order' }}
                    </v-btn>
                  </v-card-actions>
            </v-card>
          </v-dialog>
    
      <!-- {{ get_pick_order.map(item => item.name).toString() }} -->
  <v-card 
    class="text-center pt-3 pt-md-6 mx-sm-auto" 
    :disabled="!active || section_count"
    max-width="600px"
    >
    <v-btn
      icon
      x-small
      class="edit-btn"
      v-if="get_user_scope('warehouse')"
      @click="$router.push(
        item.position ? 
        `warehouse/shelve/${item.position.shelve_index + 1}?shelf=${item.position.shelf}&slot=${item.position.slot}&product=${item.position.product}` :
        'warehouse'
        )"
      >
      <v-icon>mdi-square-edit-outline</v-icon>
    </v-btn>
    <div
      class="text-center text-h2 d-flex flex-wrap justify-space-around"
      v-if="item.position"
      >
      <div>
        <p class="text-caption mb-0">Shelve</p>
        {{ item.position && item.position.shelve }}
      </div>
      <div>
        <p class="text-caption mb-0">Shelf</p>
        {{ (item.position && item.position.shelf) | LetterFilter }}
      </div>
      <div>
        <p class="text-caption mb-0">Slot</p>
        {{ (item.position && item.position.slot) | OneIndexFilter }}
      </div>
      <div>
        <p class="text-caption mb-0">Product</p>
        {{ (item.position && item.position.product) | LetterFilter }}
      </div>
    </div>
    
    <p :class="{'text-subtitle-1': !!item.position, 'text-h6': !item.position}" class="mx-2">
      {{ item.name }}
    </p>
    <p class="text-h2">
      {{ item.orders.reduce((tot, order) => tot + order.quantity, 0) }}x
    </p>
    <inner-image-zoom 
      v-if="get_product(item.uuid) && get_product(item.uuid).image && get_product(item.uuid).image.src"
      :src="get_product(item.uuid).image.src"
      hideHint
      hasSpacer
      :height="image_dimension.height"
      :width="image_dimension.width"
      :afterZoomIn="after_zoom"
      :afterZoomOut="after_zoom"
      zoomType="click"
      className="mb-4"
      />
            <!-- TODO: Bugg gör att after_zoom kallas på desktop när man navigerar ut ur bilden -->
    <v-row dense class="text-h3 mx-2" justify="space-around" align-content="center" align="center">
      <v-col  v-for="(order, i) in item.orders" :key="`order-${i}`">
        <!-- <p class="text-overline mb-0">{{ item.fulfillable_quantity }}x</p> -->
        <v-card class="px-2 bucket-col d-flex justify-center" :class="{'item-picked': !(order.quantity - order.removed_quantity - order.fulfilled_quantity)}">
        <span v-if="order.removed_quantity" class="text-decoration-line-through mr-4 ">{{ order.removed_quantity }}</span>
          {{ order.quantity - order.removed_quantity }}x
          <template v-if="company.order_bucket_size > 1">
            <v-icon>mdi-arrow-right</v-icon>
            {{ get_order_bucket_position(order.order_uuid) | LetterFilter }}
          </template>
            <v-btn
              color="red"
              icon
              absolute
              class="remove-button my-2"
              @click.stop="open_dialog(order)"
              >
              <v-icon>
                mdi-close-circle-outline
              </v-icon>
            </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-alert
      class="mx-4 mt-6 text-caption"
      max-width="600px"
      outlined
      v-if="get_product(item.uuid) && get_product(item.uuid).inventory_quantity === 0 && item.last_pick && !count_products && get_plan && get_plan.allow_inventory_count"
      >
      <v-row align="center">
        <v-col cols="12" md="9">
          This should be the last on the shelf.
        </v-col>
        <v-col cols="12" md="3" justify-self="end">
          <v-btn
            small
            color="secondary"
            @click="set_counting"
          >Not correct</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <template v-if="!require_shelf_scanning || !item.position || !item.position.printed">
        <v-btn x-large color="green" dark rounded @click="doneFulfill()" class="ma-6">
          <v-icon>
            {{ !this.count_products ? 'mdi-check' : 'mdi-arrow-down' }}
          </v-icon>
        </v-btn>
      </template>
      <template v-else>
        <transition name="slide">
        <StreamBarcodeReader 
          v-if="active" 
          class="my-3 mx-3"
          :class="{ red_border:  !qr_error }"
          @decode="on_scanned_barcode" 
          />
      </transition>
        <v-btn color="green" outlined small rounded @click="doneFulfill(true)" class="ma-6">
          Code not working
        </v-btn>
      </template>
      </v-card>
      <v-card class="mt-8 pa-4 text-center mx-sm-auto" 
        max-width="600px"
        v-if="count_products" 
        :disabled="!section_count || !active"
        :id="`countCard-${index}`"
        >
        <!-- <v-divider></v-divider> -->
        <div class="plan_banner" v-if="get_plan && !get_plan.allow_inventory_count">
          Not available on your current plan
        </div>
        <v-alert
          color="grey darken-4"
          outlined
          class="text-caption"
          >
          How many {{ item.name }} are left on the shelf, after the above {{ item.orders.length > 1 ? 'orders' : 'order'}} got picked?
        </v-alert>
        
        <div style="display:flex; justify-content: space-evenly">
          <v-btn
            color="red"
            fab
            dark
            class="my-auto"
            @click="amount_products--"
            >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="amount_products"
            v-if="get_plan.allow_inventory_count"
            hide-details
            hide-spin-buttons
            outlined
            class="inventory-input text-h3"
            type="number"
          />
          <v-text-field
            v-else
            hide-details
            outlined
            class="inventory-input text-h3"
            value="?"
            />
          <v-btn
            color="green"
            fab
            dark
            class="my-auto"
            @click="amount_products++"
            >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
        <v-btn x-large color="green" dark rounded @click="doneCount" class="ma-6">
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>

      </v-card>
 </v-container>

</template>

<script>

import { OneIndexFilter, LetterFilter } from '@/utils/filters'
import { mapGetters, mapState } from 'vuex'
import { StreamBarcodeReader } from "vue-barcode-reader";
import VueScrollTo from 'vue-scrollto'
import InnerImageZoom from 'vue-inner-image-zoom';

export default {
  components: {
    'inner-image-zoom': InnerImageZoom,
    StreamBarcodeReader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    request_count: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapState({
      require_shelf_scanning: state => state.warehouse.company.require_shelf_scanning,
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_order_bucket_position: 'order/get_order_bucket_position',
      // get_product_inventory_alarm: 'product/get_product_inventory_alarm',
      get_product: 'product/get_product',
      get_user_scope: 'user/get_user_scope',
      get_plan: 'warehouse/get_plan',
      get_data_source_by_bucket_order: 'order/get_data_source_by_bucket_order',
      get_order_by_uuid: 'order/get_order_by_uuid',
    }
      // 'order', ['get_order_bucket_position']
    ),
    image_dimension(){
      const height = this.standard_height
      const width = this.get_product(this.item.uuid).image.width / this.get_product(this.item.uuid).image.height * height
      return { width, height }
    },
    // init_count_products: function() { 
    //   return this.item.last_pick && this.get_product_inventory_alarm(this.item.uuid) 
    //   },
    amount_products: {
      get() {
        return this.get_product(this.item.uuid).inventory_quantity
      },
      set(inventory_quantity){
        this.$store.commit('product/SET_INVENTORY', { inventory_quantity, uuid: this.item.uuid })
        // return 
      }
    }
  },
  watch: {
    request_count: {
      handler: function(val){
        if(val){ 
          this.count_products = true 
          this.initial_inventory = this.get_product(this.item.uuid).inventory_quantity
        }
      },
      immediate: true,
    }
    // TODO SOMETHING
  },
  filters: {
    OneIndexFilter,
    LetterFilter,
  },
  data() {
    return {
      color: '',
      count_products: false,
      section_count: false,
      initial_inventory: 0,
      dialog: false,
      reason: 'Out of stock',
      standard_height: 150,
      selected_order_for_removal: null,
      qr_error: true,
      removed_quantity: 0,
    }
  },
  methods: {
    open_dialog(order){
      this.selected_order_for_removal = order
      this.removed_quantity = order.quantity - order.removed_quantity
      this.dialog = true
    },
    after_zoom(){
      this.standard_height = this.standard_height === 150 ? 300 : 150
    },
    set_counting(){
      this.count_products = true 
      this.initial_inventory = this.get_product(this.item.uuid).inventory_quantity
    },
    on_scanned_barcode(barcode){
      console.log(barcode);
      if(barcode === this.item.uuid) return this.doneFulfill()
      else this.qr_error = true
      // this.$emit('scan_barcode', { barcode, index: this.index })
    },
    doneFulfill(code_error){
      console.log(code_error)
      if(code_error){ 
        const { shelve, shelf, slot, product } = this.item.position
        this.$store.commit('warehouse/SET_MULTIPLE_SLOTS', [{
          shelve, shelf, slot, product, printed: false
        }])
        this.$store.dispatch('warehouse/save_shelf')
        }
      if(this.count_products && this.get_plan && this.get_plan.allow_inventory_count){
        setTimeout(() => {
            VueScrollTo.scrollTo(`#countCard-${this.index}`, 500,{
              offset: -100,
            });
          }, 100)
        return this.section_count = true
      }      
      this.$emit('done')
    },
    doneCount(){
    this.$store.dispatch('product/update_inventory', {
      uuid: this.item.uuid, 
      change: this.amount_products - this.initial_inventory 
      });
      this.$emit('done')
    },
    removeItem({ override_default }){
      this.dialog = false
      this.$store.dispatch('order/remove_item', {order: this.selected_order_for_removal, item: this.item, reason: this.reason, override_default, amount: this.removed_quantity })
    },
  },
  mounted(){

  },

  
}
</script>
<style lang="scss" scoped>
.edit-btn {
  position: absolute;
  right: 5px; 
  bottom: 5px;
}
.bucket-col {
  flex-wrap: nowrap;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.87)
}
.green-container {
  background-color: #a6f7c6
}

.remove-button {
  align-self: center;
  right:0;
}

.item-picked {
  background-color:rgba(76, 175, 80, 0.2) !important;
  
  border: 1px solid green
}

::v-deep.inventory-input {
  input {
    text-align: center;
    max-height: 3.5rem !important;
    line-height: normal !important;
    padding: 0px 0px !important;
  }
  max-width: 2em !important;
  width: 2em;
}

.product-image{
  max-height: 150px;
  max-width: 150px;
  margin-bottom: 10px;
}
.plan_banner {
  position: absolute;
    left: auto;
    width: 100%;
    /* height: 100%; */
    top: 40%;
    font-size: 2em;
    transform: rotate(-25deg);
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

.red_border {
  border: 2px solid red;
}
</style>