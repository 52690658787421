<template>
<v-alert 
  type="warning"
  border="left"
  v-if="get_printing_possible && !get_printserver_connected && company.printserver_id"
  >
  <v-row align="center">
    <v-col class="grow">
      Printserver disconnected. Make sure Printserver is running before continuing.
    </v-col>
    <v-col class="shrink">
      <v-btn
        v-if="get_user_scope('settings.printers')"
        @click="$router.push('/auth/settings/printers')"
        >
        Printer Settings
      </v-btn>
    </v-col>
  </v-row>
</v-alert>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    
  },
  data(){
    return {
      
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_printserver_connected: 'warehouse/get_printserver_connected',
      get_user_scope: 'user/get_user_scope',
    }),
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>